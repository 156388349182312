import React from 'react';

const OrderItem = ({ data, type, goNext, reOrder }) => {
  return (
    <>
      <div className="item">
        <div className="item-pic">
          <img src={data.store_img_url} />
          <div className="counter">{data.order_item_qty}</div>
        </div>
        <div
          className="item-infos"
          onClick={() => {
            goNext(data.sfcc_transaction_no);
          }}
        >
          <h2 className="title">
            {data.code}
            {data.store_name}
          </h2>
          <div className="info-box">
            <div className="detail-box">
              <p className="detail">
                品項{data.order_item_qty}・${data.amount}
              </p>
              <p className="detail">
                {data.order_date}・{data.ship_status}
              </p>
            </div>
            {type && type === 1 ? (
              <button className="sm">查看進度</button>
            ) : (
              <button
                className="sm disabled"
                onClick={(event) => {
                  event.stopPropagation(); //防止觸發外圍事件
                  // if (confirm('是否要重新下單？')) {
                  //   reOrder(data.sfcc_transaction_no);
                  // }
                  confirm('是否要重新下單？').then(() => {
                    reOrder(data.sfcc_transaction_no);
                  });
                }}
              >
                重新下單
              </button>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .item {
          padding-top: 16px;
          display: flex;
          gap: 8px;
          .item-pic {
            position: relative;
            flex-shrink: 0;
            width: 70px;
            height: 70px;
            img {
              width: 100%;
            }
            .counter {
              width: 18px;
              height: 18px;
              position: absolute;
              top: 2px;
              right: 6px;
              background-color: #f9e05f;
              border-radius: 100%;
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              color: #3b3516;
            }
          }
          .item-infos {
            width: 100%;
            padding-bottom: 16px;
            border-bottom: 1px solid #e4e4e4;
            .title {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 23px;
              color: #3b3516;
            }
            .info-box {
              display: grid;
              align-items: end;
              grid-template-columns: 1fr 90px;
              column-gap: 16px;
              .detail-box {
                .detail {
                  font-family: 'Noto Sans TC';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #999999;
                }
              }

              button {
                padding: 5px 0;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: end;
              }
            }
          }
          .action {
            flex-shrink: 0;
          }
        }
      `}</style>
    </>
  );
};

export default OrderItem;
